<template>
  <div class="container">
    <div class="box_tipping">
      <span v-html="text_tipping"></span><span v-if="showcurser">|</span>
    </div>
    <KontactShow v-if="!showcurser" />
    <br><br>
  </div>
</template>

<script>
import KontactShow from "@/components/layout/KontactShow/KontactShow";
import axios from "axios";
export default {
  name: "TippingBox",
  components: {KontactShow},
  data() {
    return {
      text: "",
      interval: null,
      curser: null,
      showcurser: true,
      akIndex: 0,
      text_tipping: ""
    }
  },
  mounted() {
    axios.get("api/kontakt-text")
        .then(response => {this.text = response.data.data.contacttext; console.log(this.text)})
    this.interval = setInterval(
        () => {
          if (this.akIndex < this.text.length) {
            if (this.text[this.akIndex] == "~") this.text_tipping += "<span style='color:#42b983'>"
            else if (this.text[this.akIndex] == "&") this.text_tipping += "</span>"
            else if (this.text[this.akIndex] == "#") this.text_tipping += "<br>"
            else this.text_tipping += this.text[this.akIndex]
            this.akIndex++
          } else this.showcurser = false;
        }, 50
    )

  },
  beforeUnmount() {
    clearInterval(this.interval)
  }
}
</script>

<style scoped>
.box_tipping {
  color: white;
  margin-top: 4em;
  width: 100%;
  padding: 3em;
  background-color: #012541;
  border-radius: 2em;
  border: none;
  margin-bottom: 5em;
  font-size: large;
}
</style>
